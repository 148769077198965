import { useContext } from 'react';
import Grid from '@mui/system/Unstable_Grid';
import { MdOutlineMenu } from 'react-icons/md';
import { SidebarContext } from '../../context/SidebarContext';
import Breadcrumb from '../../components/Breadcrumb';
import './styles.scss';

export default function SeccionTitle({ title, urlPadre = [] }) {
	const { openSidebar } = useContext(SidebarContext);
	const array = [['Home', '/admin/dashboard']];
	if (urlPadre.length > 0) {
		array.push(urlPadre);
	}
	return (
		<Grid container direction='row' alignItems='center' spacing={2}>
			<Grid xs={12} sm={12} md={12} lg={12}>
				<section className='content-area-top'>
					<div className='area-top-l'>
						<button className='sidebar-open-btn' type='button' onClick={openSidebar}>
							<MdOutlineMenu size={24} />
						</button>
						{/* <h2 className='area-top-title'>{title}</h2>
						<br /> */}
					</div>
				</section>
				<Breadcrumb links={array} actual={title} />
			</Grid>
		</Grid>
	);
}
