import { Outlet } from 'react-router-dom';
import Sidebar from '../components/sidebar/Sidebar';
import ValidateSession from '../components/ValidateSession';
import Theme from '../components/Theme';

const BaseLayout = () => {
	return (
		<ValidateSession>
			<main className='page-wrapper'>
				{/* left of page */}
				<Sidebar />
				{/* right side/content of the page */}
				<div className='content-wrapper'>
					<Outlet />
				</div>
			</main>
			<Theme />
		</ValidateSession>
	);
};

export default BaseLayout;
